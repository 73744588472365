@import url(https://fonts.googleapis.com/css2?family=Russo+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0px;
}

.logo1 {
  height: 300px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #0f1c2a;
  background-image: url(/static/media/headerLanding.72c8581b.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.gigstack {
  color: #536dff;
  font-family: "Russo One", sans-serif;
  font-size: 400%;
}

.launchEvent {
  color: #536dff;
  font-size: 530%;
  font-family: "DM Sans", sans-serif;
  background-color: #ecfdff;
  padding: 0px 20px;
}

.text3 {
  color: white;
  font-family: "DM Sans", sans-serif;
  font-size: 170%;
  font-weight: 400;
}

.fecha1 {
  color: white;
  font-family: "DM Sans", sans-serif;
  font-size: 670%;
}

.hora {
  color: white;
  font-family: "DM Sans", sans-serif;
  font-size: 200%;
  padding-right: 10px;
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.letrasGig {
  color: #fff;
  font-family: "DM Sans", sans-serif;
}

.contratar {
  font-family: "DM Sans", sans-serif;
  color: #536dff;
  background-color: #ecfdff;
  font-weight: bold;
}

.spacePadding {
  padding: 10px 20px 10px 70px;
}

@media (max-width: 667px) {
  .spacePadding {
    padding: 10px 40px;
  }
}

.container {
  width: 300px;

  height: 100%;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0.3px);
  -webkit-backdrop-filter: blur(00.3px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 80px auto;
}

img {
  width: 100px;
  position: relative;
  top: -40px;
  left: 33%;
}

h2 {
  text-align: center;
  text-transform: uppercase;
}

.formGlass {
  margin: 30px;
}

label {
  display: block;
  color: #0f1c2a;
  margin-top: 10px;
}

.inputGlass {
  display: block;
  width: 100%;
  background: #fff;
  border: none;
  outline: none;
  border-bottom: 1px solid white;
  padding: 10px;
  border-radius: 5px;
}

::-webkit-input-placeholder {
  color: white;
  opacity: 1;
}

:-ms-input-placeholder {
  color: white;
  opacity: 1;
}

::placeholder {
  color: white;
  opacity: 1;
}

.boton {
  width: 100%;
  margin-top: 5px;
  padding: 8px;
  border: none;
  outline: none;
  background: #536dff;
  color: white;
  font-size: 17px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 5px;
}

button span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

button span:after {
  content: "\BB";
  position: absolute;
  opacity: 0;
  right: -15px;
  -webkit-transform: 0.5s;
          transform: 0.5s;
}

button:hover span {
  padding-right: 25px;
}

button:hover span:after {
  opacity: 1;
  right: 0;
}

.rounded .ant-form input[type="checkbox"] {
  border-radius: 50% !important;
}

.rounded .ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 50% !important;
}
.ant-checkbox span span {
  color: #0f1c2a !important;
  font-size: 14px;
  font-weight: 500 !important;
}

