.container {
  width: 300px;

  height: 100%;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0.3px);
  -webkit-backdrop-filter: blur(00.3px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 80px auto;
}

img {
  width: 100px;
  position: relative;
  top: -40px;
  left: 33%;
}

h2 {
  text-align: center;
  text-transform: uppercase;
}

.formGlass {
  margin: 30px;
}

label {
  display: block;
  color: #0f1c2a;
  margin-top: 10px;
}

.inputGlass {
  display: block;
  width: 100%;
  background: #fff;
  border: none;
  outline: none;
  border-bottom: 1px solid white;
  padding: 10px;
  border-radius: 5px;
}

::placeholder {
  color: white;
  opacity: 1;
}

.boton {
  width: 100%;
  margin-top: 5px;
  padding: 8px;
  border: none;
  outline: none;
  background: #536dff;
  color: white;
  font-size: 17px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 5px;
}

button span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  right: -15px;
  transform: 0.5s;
}

button:hover span {
  padding-right: 25px;
}

button:hover span:after {
  opacity: 1;
  right: 0;
}

.rounded .ant-form input[type="checkbox"] {
  border-radius: 50% !important;
}

.rounded .ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 50% !important;
}
.ant-checkbox span span {
  color: #0f1c2a !important;
  font-size: 14px;
  font-weight: 500 !important;
}
