@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap");

.letrasGig {
  color: #fff;
  font-family: "DM Sans", sans-serif;
}

.contratar {
  font-family: "DM Sans", sans-serif;
  color: #536dff;
  background-color: #ecfdff;
  font-weight: bold;
}

.spacePadding {
  padding: 10px 20px 10px 70px;
}

@media (max-width: 667px) {
  .spacePadding {
    padding: 10px 40px;
  }
}
