@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #0f1c2a;
  background-image: url(../imagenes/headerLanding.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.gigstack {
  color: #536dff;
  font-family: "Russo One", sans-serif;
  font-size: 400%;
}

.launchEvent {
  color: #536dff;
  font-size: 530%;
  font-family: "DM Sans", sans-serif;
  background-color: #ecfdff;
  padding: 0px 20px;
}

.text3 {
  color: white;
  font-family: "DM Sans", sans-serif;
  font-size: 170%;
  font-weight: 400;
}

.fecha1 {
  color: white;
  font-family: "DM Sans", sans-serif;
  font-size: 670%;
}

.hora {
  color: white;
  font-family: "DM Sans", sans-serif;
  font-size: 200%;
  padding-right: 10px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}
